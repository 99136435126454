<template>
	<v-flex pb-3 shrink>
		<v-layout v-if="loading" align-center class="w-loading-container" justify-center style="max-height: 51px; min-height: 51px">
			<v-progress-circular color="primary" indeterminate />
		</v-layout>
		<v-alert color="warning" icon="warning" :value="!loading && value.length == 0">{{ noDataText }}</v-alert>
		<v-expansion-panel :value="panel" @input="setPanel">
			<ApplicationVendorPanel
				v-for="vendor in value"
				:key="vendor.id"
				:fields="params"
				:search="search"
				:title="vendor.company"
				:magic-mail-models-reg-ex="magicMailModelsRegEx"
				:value="vendor"
				@select="select(vendor, $event)"
				@unselect="unselect(vendor)"
			/>
		</v-expansion-panel>
	</v-flex>
</template>

<script>
import ApplicationsModuleGuard from '@/mixins/ModulesGuards/Applications/ApplicationsModuleGuard'

export default {
	name: 'ApplicationVendorExpansionPanel',
	components: {
		ApplicationVendorPanel: () => ({
			component: import('@/components/Applications/ApplicationVendorPanel')
		})
	},
	mixins: [ApplicationsModuleGuard],
	props: {
		loading: {
			default: false,
			required: false,
			type: Boolean
		},
		params: {
			default: () => [],
			required: false,
			type: Array
		},
		value: {
			default: () => [],
			required: false,
			type: Array
		},
		search: {
			default: '',
			required: false,
			type: String
		},
	},
	data: function () {
		return {
			magicMailModels: [],
			panel: null
		}
	},
	computed: {
		hasEmailField: function () {
			return this.params.some(field => {
				return field.type == 'email' && !field.auto
			})
		},
		isAdmin: function () {
			return this.selectedAccountingFirm?.isAccountantAdmin ?? false
		},
		magicMailModelsRegEx: function () {
			const prefixes = []
			const suffixes = []

			this.magicMailModels.forEach(({ prefix, suffix }) => {
				prefix = `(${prefix.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&')})`
				suffix = `(${suffix.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&')})`

				if (!prefixes.includes(prefix)) {
					prefixes.push(prefix)
				}
				if (!suffixes.includes(suffix)) {
					suffixes.push(suffix)
				}
			})

			return new RegExp(`^(${prefixes.join('|')}).*(${suffixes.join('|')})$`, 'i')
		},
		noDataText: function () {
			let result = ''
			if (this.isAdmin) {
				result = this.$t('application.warning.no_client')
			} else {
				result = this.$t('application.warning.no_client_access')
			}

			return result
		}
	},
	watch: {
		hasEmailField: {
			handler: function (newVal) {
				if (newVal && this.magicMailModels.length == 0) {
					this.loadMagicMailModels()
				}
			}
		}
	},
	mounted: function () {
		if (this.hasEmailField) {
			this.loadMagicMailModels()
		}
	},
	methods: {
		loadMagicMailModels: function () {
			this.service.listMagicMailModels().then(magicMailModels => {
				this.magicMailModels = magicMailModels
			})
		},
		select: function (row, params = {}) {
			this.$emit('select', row, params)
		},
		setPanel: function (panel) {
			this.panel = panel
		},
		unselect: function (row) {
			this.$emit('unselect', row)
		}
	}
}
</script>